angular.module('cpformplastApp')
  .controller('AssignMachineModal', function ($scope, subjob, DataManager, Notification) {

    $scope.subjob = subjob;
    $scope.suggestedMachines = [];
    $scope.otherMachines = [];
    $scope.selectedMachine = null;
    $scope.isLoading = false;

    $scope.init = () => $scope.fetchMachines();

    $scope.fetchMachines = () => {
      DataManager.fetchSubjobSchedules().then(data => {
        $scope.suggestedMachines = data.filter(m => subjob.appropriateMachinesId.includes(m.id));
        $scope.otherMachines = data.filter(m => !subjob.appropriateMachinesId.includes(m.id));
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un probème est survenu lors du chargement des jobs');
      });
    };

    $scope.selectMachine = (machine) => {
      $scope.selectedMachine = machine;
    };

    $scope.chooseMachine = () => {
      const machine = $scope.selectedMachine;
      if (!machine) {
        Notification.error('Vous devez choisir une machine');
        return;
      }
      const startDate = machine.subjobSchedules.length > 0 ? machine.subjobSchedules[machine.subjobSchedules.length-1].endDate : new Date();

      DataManager.createSubjobSchedule(subjob, $scope.selectedMachine, startDate)
      .then(() =>{
        Notification.success('La job a été assignée');
        $scope.$dismiss();
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu');
      });
    };

    $scope.init();

  });
